import { SizeFilter } from 'src/types/graphql'
import { IQueryParams } from 'src/types/types'

export enum Competitors {
  UStorage = 'u-storage',
  Multibodegas = 'multibodegas',
  MasEspacio = 'mas-espacio',
  GuardaBox = 'guardabox',
  Spakio = 'spakio'
}

export enum SearchPage {
  Bodega1 = 'bodegas-en-renta',
  Bodega2 = 'self-storage',
  Bodega3 = 'mini-bodegas',
  Estacionamiento1 = 'cajon-de-estacionamiento',
  Estacionamiento2 = 'estacionamiento-cerca-a-mi',
  Estacionamiento3 = 'estacionamiento-y-pension',
  Estacionamiento4 = 'renta-de-estacionamiento'
}

export const SEARCH_PAGE_MAP: {
  [key in SearchPage]: { searchName: string; query: Partial<IQueryParams> }
} = {
  [SearchPage.Bodega1]: {
    searchName: 'bodegas en renta',
    query: { canStoreVehicle: false, size: SizeFilter.Large }
  },
  [SearchPage.Bodega2]: {
    searchName: 'self storage',
    query: { canStoreVehicle: false, size: SizeFilter.Small }
  },
  [SearchPage.Bodega3]: {
    searchName: 'mini bodegas en renta',
    query: { canStoreVehicle: false, size: SizeFilter.Small }
  },
  [SearchPage.Estacionamiento1]: {
    searchName: 'cajon de estacionamiento',
    query: { canStoreVehicle: true }
  },
  [SearchPage.Estacionamiento2]: {
    searchName: 'estacionamiento cerca de mi',
    query: { canStoreVehicle: true }
  },
  [SearchPage.Estacionamiento3]: {
    searchName: 'estacionamiento y pensión',
    query: { canStoreVehicle: true }
  },
  [SearchPage.Estacionamiento4]: {
    searchName: 'renta de estacionamiento',
    query: { canStoreVehicle: true }
  }
}

export enum SearchPageCity {
  Base = '',
  Monterrey = 'monterrey',
  Cdmx = 'cdmx',
  CiudadDeMexico = 'ciudad-de-mexico',
  Guadalajara = 'guadalajara',
  Tijuana = 'tijuana',
  Puebla = 'puebla',
  Queretaro = 'queretaro',
  Leon = 'leon',
  Chihuahua = 'chihuahua',
  Merida = 'merida',
  Cancun = 'cancun',
  Aguascalientes = 'aguascalientes',
  Hermosillo = 'hermosillo',
  PuertoVallarta = 'puerto-vallarta',
  Toluca = 'toluca',
  LosCabos = 'los-cabos',
  Zapopan = 'zapopan'
}

export enum MexicoCityHoods {
  Iztapalapa = 'iztapalapa',
  DeValle = 'del-valle',
  Tlalnepantla = 'tlalnepantla',
  ValleDeChalco = 'valle-de-chalco',
  Azcapotzalco = 'azcapotzalco',
  Naucalpan = 'naucalpan',
  Polanco = 'polanco',

  LasLomas = 'las-lomas',
  Centro = 'centro',
  Condesa = 'condesa',
  Roma = 'roma',
  ColoniaJuarez = 'colonia-juarez',
  Coyoacan = 'coyoacan',
  JardinesDelPedregal = 'jardines-del-pedregal',
  LomasDeChapultepec = 'lomas-de-chapultepec',
  Napoles = 'napoles',
  SanAngel = 'san-angel',
  SantaFe = 'santa-fe',
  Tepito = 'tepito',
  Tlatelolco = 'tlatelolco',
  Anzures = 'anzures',
  Narvarte = 'narvarte',
  Escandon = 'escandon',
  SanRafael = 'san-rafael',
  Doctores = 'doctores',
  Guerrero = 'guerrero',
  Obrera = 'obrera'
}

export enum MonterreyHoods {
  Apodaca = 'apodaca',
  Cumbres = 'cumbres',
  Escobedo = 'general-escobedo',
  Guadalupe = 'guadalupe',
  SantaCatarina = 'santa-catarina',
  SanNicolas = 'san-nicolas',
  SanPedro = 'san-pedro-garza-garcia'
}

export enum GuadalajaraHoods {
  Zapopan = 'zapopan',
  Tlaquepaque = 'san-pedro-tlaquepaque',
  Tonala = 'tonala',
  Tlajomulco = 'tlajomulco-de-zuniga',
  ElSalto = 'el-salto'
}

export type SearchHood = MexicoCityHoods | MonterreyHoods | GuadalajaraHoods

export const SEARCH_PAGE_CITY_MAP: {
  [key in SearchPageCity]: {
    cityName: string
    placeId: string
    hoods?: SearchHood[]
  }
} = {
  [SearchPageCity.Base]: { cityName: '', placeId: '' },
  [SearchPageCity.Monterrey]: {
    cityName: 'Monterrey',
    placeId: 'ChIJ9fg3tDGVYoYRlJjIasrT06M',
    hoods: Object.values(MonterreyHoods)
  },
  [SearchPageCity.Cdmx]: {
    cityName: 'CDMX',
    placeId: 'ChIJB3UJ2yYAzoURQeheJnYQBlQ',
    hoods: Object.values(MexicoCityHoods)
  },
  [SearchPageCity.CiudadDeMexico]: {
    cityName: 'Ciudad de México',
    placeId: 'ChIJB3UJ2yYAzoURQeheJnYQBlQ',
    hoods: Object.values(MexicoCityHoods)
  },
  [SearchPageCity.Guadalajara]: {
    cityName: 'Guadalajara',
    placeId: 'ChIJm9MvtYyxKIQRUFeGvwKTPdY',
    hoods: Object.values(GuadalajaraHoods)
  },
  [SearchPageCity.Tijuana]: {
    cityName: 'Tijuana',
    placeId: 'ChIJ03tYJgI52YARViTmpK9LchQ'
  },
  [SearchPageCity.Puebla]: {
    cityName: 'Puebla',
    placeId: 'ChIJO3q8Xr3Az4URla2U5B1Gpkg'
  },
  [SearchPageCity.Queretaro]: {
    cityName: 'Querétaro',
    placeId: 'ChIJVZJb3I9b04URL4MbVqqUsJc'
  },
  [SearchPageCity.Leon]: {
    cityName: 'León',
    placeId: 'ChIJIefm1v--K4QRJ0OlYeyVbWA'
  },
  [SearchPageCity.Chihuahua]: {
    cityName: 'Chihuahua',
    placeId: 'ChIJM0BIXZ1E6oYRex3dBqen8bc'
  },
  [SearchPageCity.Merida]: {
    cityName: 'Mérida',
    placeId: 'ChIJFw1Fq1xxVo8RCeurFVcV_F0'
  },
  [SearchPageCity.Cancun]: {
    cityName: 'Cancún',
    placeId: 'ChIJ21P2rgUrTI8Ris1fYjy3Ms4'
  },
  [SearchPageCity.Aguascalientes]: {
    cityName: 'Aguascalientes',
    placeId: 'ChIJ-9nkOhTsKYQRIFKcZ3hpAQQ'
  },
  [SearchPageCity.Hermosillo]: {
    cityName: 'Hermosillo',
    placeId: 'ChIJ5a7femiEzoYR-X-I6ZVTPbM'
  },
  [SearchPageCity.PuertoVallarta]: {
    cityName: 'Puerto Vallarta',
    placeId: 'ChIJf8hjUGNFIYQRdFK_hZm_z3k'
  },
  [SearchPageCity.Toluca]: {
    cityName: 'Toluca',
    placeId: 'ChIJuetQKomJzYURxAgCVdVKP60'
  },
  [SearchPageCity.LosCabos]: {
    cityName: 'Los Cabos',
    placeId: 'ChIJoQjlifNKr4YRaLiiwWLfpEs'
  },
  [SearchPageCity.Zapopan]: {
    cityName: 'Zapopan',
    placeId: 'ChIJE0xwqWGuKIQRrALQftSl2K8'
  }
}

export const HOOD_MAP: {
  [key in SearchHood]: { hoodName: string; placeId: string }
} = {
  [MexicoCityHoods.Anzures]: {
    hoodName: 'Anzures',
    placeId: 'ChIJ2cEpRk3_0YURe0nsHZ-75g0'
  },
  [MexicoCityHoods.Azcapotzalco]: {
    hoodName: 'Azcapotzalco',
    placeId: 'ChIJzRm0I9P50YURcbV6UCCW_yQ'
  },
  [MexicoCityHoods.Centro]: {
    hoodName: 'Centro',
    placeId: 'ChIJ8bh9yyz50YURlL2cFjXnr98'
  },
  [MexicoCityHoods.Coyoacan]: {
    hoodName: 'Coyoacan',
    placeId: 'ChIJwy80ES4AzoURgODUMdhnJpo'
  },
  [MexicoCityHoods.Condesa]: {
    hoodName: 'Condesa',
    placeId: 'ChIJ4zXmiUT_0YUR57jJi24HHO0'
  },
  [MexicoCityHoods.ColoniaJuarez]: {
    hoodName: 'Colonia Juarez',
    placeId: 'ChIJBwSKbzT_0YURL8cF7c7Nctk'
  },
  [MexicoCityHoods.DeValle]: {
    hoodName: 'Del Valle',
    placeId: 'ChIJ-f36vp__0YUR80l67xodJ1I'
  },
  [MexicoCityHoods.Doctores]: {
    hoodName: 'Doctores',
    placeId: 'ChIJP1kS1yb_0YURtc9uxpN8P7o'
  },
  [MexicoCityHoods.Escandon]: {
    hoodName: 'Escandon',
    placeId: 'ChIJfyqLqmj_0YURY_XtfNcVz1U'
  },
  [MexicoCityHoods.Guerrero]: {
    hoodName: 'Guerrero',
    placeId: 'ChIJsSu1Myb50YURnMmlJi0w05o'
  },
  [MexicoCityHoods.Iztapalapa]: {
    hoodName: 'Iztapalapa',
    placeId: 'ChIJiQcnB4ICzoURqfhU7FCS5cE'
  },
  [MexicoCityHoods.JardinesDelPedregal]: {
    hoodName: 'Jardines del Pedregal',
    placeId: 'ChIJxbqHQez_zYURC023DLxszcs'
  },
  [MexicoCityHoods.LasLomas]: {
    hoodName: 'Las Lomas',
    placeId: 'ChIJjVCsD44B0oUR_zUzt8kjJqw'
  },
  [MexicoCityHoods.LomasDeChapultepec]: {
    hoodName: 'Lomas de Chapultepec',
    placeId: 'ChIJjVCsD44B0oUR_zUzt8kjJqw'
  },
  [MexicoCityHoods.Napoles]: {
    hoodName: 'Napoles',
    placeId: 'ChIJVVDq_XD_0YURqBXij6GFdPQ'
  },
  [MexicoCityHoods.Naucalpan]: {
    hoodName: 'Naucalpan',
    placeId: 'ChIJ7y7PWEkB0oURn-ssxjwYNsA'
  },
  [MexicoCityHoods.Narvarte]: {
    hoodName: 'Narvarte',
    placeId: 'ChIJ8Yd8Wwb_0YURSBv8PSVer4E'
  },
  [MexicoCityHoods.Obrera]: {
    hoodName: 'Obrera',
    placeId: 'ChIJt6H2Ptz-0YURradu-E-kIek'
  },
  [MexicoCityHoods.Polanco]: {
    hoodName: 'Polanco',
    placeId: 'ChIJ-UNIbQMC0oURdqnTwDJ30-Q'
  },
  [MexicoCityHoods.Roma]: {
    hoodName: 'Roma',
    placeId: 'ChIJzeSi8jn_0YURMiXDZ-gzmi0'
  },
  [MexicoCityHoods.SanAngel]: {
    hoodName: 'San Angel',
    placeId: 'ChIJ8_W4AQMA0oURuyq-JGd9Cms'
  },
  [MexicoCityHoods.SantaFe]: {
    hoodName: 'Santa Fe',
    placeId: 'ChIJY1InSKwB0oURb6dkqE4h63Q'
  },
  [MexicoCityHoods.SanRafael]: {
    hoodName: 'San Rafael',
    placeId: 'ChIJj9Tx8cv40YURKcx2uQSxoYs'
  },
  [MexicoCityHoods.Tepito]: {
    hoodName: 'Tepito',
    placeId: 'ChIJA_8cTjr50YURKmq5IIv_l9E'
  },
  [MexicoCityHoods.Tlalnepantla]: {
    hoodName: 'Tlalnepantla',
    placeId: 'ChIJYxCNbHb40YUR9s1Fdr8kGJk'
  },
  [MexicoCityHoods.Tlatelolco]: {
    hoodName: 'Tlatelolco',
    placeId: 'ChIJeZh2bSH50YURRFVyBPkQKgQ'
  },
  [MexicoCityHoods.ValleDeChalco]: {
    hoodName: 'Valle de Chalco',
    placeId: 'ChIJ7z-C0zUczoURDvbvTV8zbJE'
  },

  [MonterreyHoods.Apodaca]: {
    hoodName: 'Apodaca',
    placeId: 'ChIJRX6YMS_vYoYR1Lg9kQNoM38'
  },
  [MonterreyHoods.Cumbres]: {
    hoodName: 'Cumbres',
    placeId: 'ChIJ9wiwHF2WYoYROeaOFchmO8g'
  },
  [MonterreyHoods.Escobedo]: {
    hoodName: 'General Escobedo',
    placeId: 'ChIJl9ccRqSTYoYRAoX080KMn0w'
  },
  [MonterreyHoods.Guadalupe]: {
    hoodName: 'Guadalupe',
    placeId: 'ChIJsTbEclYwY4YRrvU1TovCwVc'
  },
  [MonterreyHoods.SanNicolas]: {
    hoodName: 'San Nicolas',
    placeId: 'ChIJAw16yguTYoYRpyEPQQtm8ho'
  },
  [MonterreyHoods.SanPedro]: {
    hoodName: 'San Pedro Garza Garcia',
    placeId: 'ChIJqXEv8aC9YoYRrv9zsD_mRA4'
  },
  [MonterreyHoods.SantaCatarina]: {
    hoodName: 'Santa Catarina',
    placeId: 'ChIJHzSQDGyYYoYRpWP_MHAZB3E'
  },

  [GuadalajaraHoods.ElSalto]: {
    hoodName: 'El Salto',
    placeId: 'ChIJt9NHh1hKL4QRFqzO2AILfbI'
  },
  [GuadalajaraHoods.Tlaquepaque]: {
    hoodName: 'San Pedro Tlaquepaque',
    placeId: 'ChIJA0pBpoezKIQREKq-cByLC14'
  },
  [GuadalajaraHoods.Tlajomulco]: {
    hoodName: 'Tlajomulco de Zuniga',
    placeId: 'ChIJk0R9BvdTL4QRL95OIvTG3_k'
  },
  [GuadalajaraHoods.Tonala]: {
    hoodName: 'Tonala',
    placeId: 'ChIJW7Bu_fi0KIQRqgroLfZ5CZ0'
  },
  [GuadalajaraHoods.Zapopan]: {
    hoodName: 'Zapopan',
    placeId: 'ChIJE0xwqWGuKIQRrALQftSl2K8'
  }
}

export const SEARCH_LINKS_MAP: {
  [key in SearchPage]: { [key in SearchPageCity]: string }
} = Object.values(SearchPage).reduce(
  (memo, page) => {
    return {
      ...memo,
      [page]: Object.values(SearchPageCity).reduce((memo, city) => {
        return {
          ...memo,
          [city]: `/${page}${city ? `/${city}` : ''}`
        }
      }, {})
    }
  },
  {} as { [key in SearchPage]: { [key in SearchPageCity]: string } }
)
